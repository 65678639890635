"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _polished = require("polished");
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const Tooltip = _styledComponents.default.div`
  background-color: ${({
  theme
}) => theme.colors.raisinBlack};
  border-radius: ${(0, _polished.rem)(4)};
  color: ${({
  theme
}) => theme.colors.white};
  font-size: 14px;
  opacity: 0.7;
  padding: 12px;
  pointer-events: none;
  position: absolute;
  min-width: ${(0, _polished.rem)(200)};
`;
var _default = Tooltip;
exports.default = _default;