"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadBlob = downloadBlob;
/**
 * Instructs the browser to initiate a download for an arbitrary blob of data.
 *
 * @param blob - The blob of data to tell the browser to download
 * @param filename - Optionally, tell the browser what the name of file should be when downloading
 */
function downloadBlob(blob, filename) {
  if (typeof Image !== 'function') throw 'This function requires a browser environment to run';

  // Create a URL for the blob of data
  const url = window.URL.createObjectURL(blob);

  // In order to reliably have the browser intiate a download, an off-screen <a> tag is created
  // with it's `href` attribute set to the url object we created above.
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = url;

  // In order to set the name of the file, we use the <a> tag's `download` attribute
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download
  if (filename) link.download = filename;

  // Simulate a click on the link by initiating a click event.
  link.dispatchEvent(new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  }));

  // Clean up by removing the <a> tag and destroying the url obkect.
  link.remove();
  window.URL.revokeObjectURL(url);
}