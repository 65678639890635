"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scaleBetween = void 0;
// Type defs

const scaleBetween = (unscaledNum, min, max, minRange, maxRange) => {
  return (maxRange - minRange) * (unscaledNum - min) / (max - min) + minRange;
};
exports.scaleBetween = scaleBetween;