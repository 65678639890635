import { camelCase, snakeCase } from '@a1s/lib';
import { ApolloLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

const link = new RestLink({
  endpoints: {
    customers: { uri: 'pov_customers' },
    reports: { uri: 'pov' },
  },
  fieldNameDenormalizer: (key) => snakeCase(key),
  fieldNameNormalizer: (key) => camelCase(key),
}) as unknown;

export default link as ApolloLink;
