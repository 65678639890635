"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rhythm = exports.BASE_LINE_HEIGHT = exports.BASE_FONT_SIZE = void 0;
exports.rhythmUnits = rhythmUnits;
var _compassVerticalRhythm = _interopRequireDefault(require("compass-vertical-rhythm"));
var _parseUnit = _interopRequireDefault(require("parse-unit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
// Constants
// -------------------------------------------------------------------------------------------------

/**
 * The base font-size (in pixels) that all text is based off of. This will be used to calculte vertical rhythm and also
 * used to calculate rem units.
 */
const BASE_FONT_SIZE = 16;

/**
 * The base line-height that all text is bassed off of. This will be used to caculate vertical rhythm, including the
 * theme's spacing.
 */
exports.BASE_FONT_SIZE = BASE_FONT_SIZE;
const BASE_LINE_HEIGHT = 1.5;

//
// Vertical rhythm helpers
// -------------------------------------------------------------------------------------------------
exports.BASE_LINE_HEIGHT = BASE_LINE_HEIGHT;
const rhythm = (0, _compassVerticalRhythm.default)({
  baseFontSize: `${BASE_FONT_SIZE}px`,
  baseLineHeight: BASE_LINE_HEIGHT
});

/**
 * Calculate rhythm units, returns an integer representing the number of pixels.
 */
exports.rhythm = rhythm;
function rhythmUnits(lines) {
  const [remUnit] = (0, _parseUnit.default)(rhythm.rhythm(lines));
  return remUnit * BASE_FONT_SIZE;
}