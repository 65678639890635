"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  pdf: true,
  Canvas: true,
  Font: true
};
Object.defineProperty(exports, "Canvas", {
  enumerable: true,
  get: function () {
    return _renderer.Canvas;
  }
});
Object.defineProperty(exports, "Font", {
  enumerable: true,
  get: function () {
    return _renderer.Font;
  }
});
Object.defineProperty(exports, "pdf", {
  enumerable: true,
  get: function () {
    return _renderer.pdf;
  }
});
var _Alert = require("./Alert");
Object.keys(_Alert).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Alert[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Alert[key];
    }
  });
});
var _Box = require("./Box");
Object.keys(_Box).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Box[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Box[key];
    }
  });
});
var _Check = require("./Check");
Object.keys(_Check).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Check[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Check[key];
    }
  });
});
var _Definition = require("./Definition");
Object.keys(_Definition).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Definition[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Definition[key];
    }
  });
});
var _DividerBlock = require("./DividerBlock");
Object.keys(_DividerBlock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DividerBlock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DividerBlock[key];
    }
  });
});
var _Document = require("./Document");
Object.keys(_Document).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Document[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Document[key];
    }
  });
});
var _DomainsTable = require("./DomainsTable");
Object.keys(_DomainsTable).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DomainsTable[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DomainsTable[key];
    }
  });
});
var _Error = require("./Error");
Object.keys(_Error).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Error[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Error[key];
    }
  });
});
var _FlatLogo = require("./FlatLogo");
Object.keys(_FlatLogo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FlatLogo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FlatLogo[key];
    }
  });
});
var _Flock = require("./Flock");
Object.keys(_Flock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Flock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Flock[key];
    }
  });
});
var _Graph = require("./Graph");
Object.keys(_Graph).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Graph[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Graph[key];
    }
  });
});
var _Heading = require("./Heading");
Object.keys(_Heading).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Heading[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Heading[key];
    }
  });
});
var _Image = require("./Image");
Object.keys(_Image).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Image[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Image[key];
    }
  });
});
var _Line = require("./Line");
Object.keys(_Line).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Line[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Line[key];
    }
  });
});
var _Link = require("./Link");
Object.keys(_Link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Link[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Link[key];
    }
  });
});
var _Logo = require("./Logo");
Object.keys(_Logo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Logo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Logo[key];
    }
  });
});
var _LongHexPattern = require("./LongHexPattern");
Object.keys(_LongHexPattern).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _LongHexPattern[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _LongHexPattern[key];
    }
  });
});
var _Map = require("./Map");
Object.keys(_Map).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Map[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Map[key];
    }
  });
});
var _Markdown = require("./Markdown");
Object.keys(_Markdown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Markdown[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Markdown[key];
    }
  });
});
var _OverviewStats = require("./OverviewStats");
Object.keys(_OverviewStats).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _OverviewStats[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _OverviewStats[key];
    }
  });
});
var _Page = require("./Page");
Object.keys(_Page).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Page[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Page[key];
    }
  });
});
var _Position = require("./Position");
Object.keys(_Position).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Position[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Position[key];
    }
  });
});
var _PRAReport = require("./PRAReport");
Object.keys(_PRAReport).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PRAReport[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PRAReport[key];
    }
  });
});
var _renderBlob = require("./renderBlob");
Object.keys(_renderBlob).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _renderBlob[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _renderBlob[key];
    }
  });
});
var _Rotate = require("./Rotate");
Object.keys(_Rotate).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Rotate[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Rotate[key];
    }
  });
});
var _Row = require("./Row");
Object.keys(_Row).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Row[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Row[key];
    }
  });
});
var _Stack = require("./Stack");
Object.keys(_Stack).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Stack[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Stack[key];
    }
  });
});
var _TallHexPattern = require("./TallHexPattern");
Object.keys(_TallHexPattern).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TallHexPattern[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TallHexPattern[key];
    }
  });
});
var _Table = require("./Table");
Object.keys(_Table).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Table[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Table[key];
    }
  });
});
var _Text = require("./Text");
Object.keys(_Text).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Text[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Text[key];
    }
  });
});
var _Wordmark = require("./Wordmark");
Object.keys(_Wordmark).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Wordmark[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Wordmark[key];
    }
  });
});
var _renderer = require("@react-pdf/renderer");