"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.camelCase = camelCase;
exports.snakeCase = snakeCase;
var _camelCase = _interopRequireDefault(require("lodash/camelCase"));
var _snakeCase = _interopRequireDefault(require("lodash/snakeCase"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Converts string to camel case.
 *
 * @param string The string to convert.
 * @return Returns the camel cased string.
 */
function camelCase(string) {
  const transformed = (0, _camelCase.default)(addPlaceholders(string));
  return removePlaceholders(transformed);
}

/**
 * Converts string to snake case.
 *
 * @param string The string to convert.
 * @return Returns the snake cased string.
 */
function snakeCase(string) {
  const transformed = (0, _snakeCase.default)(addPlaceholders(string));
  return removePlaceholders(transformed);
}

//
// Private functions
//
// A few edge cases in our codebase use a date string like '12/01/2021' as keys. The problem is that lodash's
// case functions strip out the '/' character. In order to avoid this, we replace the slash character with a
// placeholder character before making the transform and put the slash character back after the string transformation.
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Use the unicode placeholder character to store where a `/` character will be while transforming.
 */
const PLACEHOLDER = '\uFFFC';

/**
 * Replaces `"/"` characters with the placeholder character.
 */
function addPlaceholders(string) {
  return string === null || string === void 0 ? void 0 : string.replace(/\//g, PLACEHOLDER);
}

/**
 * Replaces the placeholder characters with a `"/"` character.
 */
function removePlaceholders(string) {
  return string.replace(new RegExp(PLACEHOLDER, 'g'), '/');
}