"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encodeBase64 = encodeBase64;
/**
 * Encodes a string with Base64 encoding.
 *
 * @param string - The string to encode.
 */
function encodeBase64(str) {
  return btoa(unescape(encodeURIComponent(str)));
}