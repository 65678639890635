"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderBlob = renderBlob;
var _pdf = _interopRequireDefault(require("worker-loader!./pdf.worker"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// eslint-disable-next-line import/no-unresolved

async function renderBlob(props) {
  const worker = new _pdf.default();
  worker.postMessage(props);
  return new Promise(resolve => {
    worker.onmessage = event => {
      worker.terminate();
      resolve(event.data);
    };
  });
}