import {
  Box,
  Checkbox,
  CircleButton,
  DateSelect,
  Dropdown,
  Field,
  InputGroup,
  Label,
  Position,
  Row,
  Stack,
  TextInput,
} from '@a1s/ui-deprecated';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useFormikContext, Field as FormikField, FieldProps, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormData } from '../data';

//
// Main component
// -------------------------------------------------------------------------------------------------

const CUSTOMERS_QUERY = gql`
  query CustomerQuery {
    customers @rest(endpoint: "customers", path: "/", type: "Customer") {
      customerId
      customerName
    }
  }
`;

export interface FormProps {
  /**
   * The data that should be displayed on the form.
   */
  data: FormData;

  /**
   * Callback for when the form is submitted and the PDF should be rendered.
   */
  onSubmit?: (values: FormData) => void | Promise<unknown>;
}

export default function Form({ data, onSubmit }: FormProps): JSX.Element {
  const { t } = useTranslation('defaults');

  const initialValues = {
    becDescription: data.becDescription,
    company: data.company,
    companyId: data.companyId,
    countryOfOriginSummary: data.countryOfOriginSummary,
    customizedPartner: data.customizedPartner,
    detectionExamples: data.detectionExamples,
    displayPerson: data.displayPerson,
    documentDate: data.documentDate,
    documentTitle: data.documentTitle,
    overview: data.overview,
    partnerName: data.partnerName,
    personName: data.personName,
    personTitle: data.personTitle,
    reportPeriodEnd: data.reportPeriodEnd,
    reportPeriodStart: data.reportPeriodStart,
    targetSummary: data.targetSummary,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: apiData } = useQuery<{ customers?: any[] }>(CUSTOMERS_QUERY);
  const customers = apiData?.customers?.map(({ customerId, customerName }) => ({
    key: customerId,
    text: customerName,
  }));

  function handleSubmit(submitted: FormData) {
    // Remove falsey (i.e. '') values before calling the `onSubmit` callback.
    const values: FormData = Object.entries(submitted).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return { ...acc, [key]: value };
    }, {} as FormData);

    onSubmit?.(values);
  }

  return (
    <Box padding={2}>
      <Formik<FormData> enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        <FormikForm>
          <Stack space={2}>
            <Stack space={1}>
              <Field>
                {customers && (
                  <Stack>
                    <Label>Company</Label>
                    <FormikField name="companyId">
                      {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
                        const selected = customers.find((c) => c.key === value);
                        return (
                          <Dropdown
                            onChange={([selected]) => {
                              setFieldValue('company', selected?.text);
                              setFieldValue('companyId', selected?.key);
                            }}
                            multiple={false}
                            options={customers || []}
                            placeholder={t('company')}
                            value={selected && [selected]}
                          />
                        );
                      }}
                    </FormikField>
                  </Stack>
                )}

                <Row justify="stretch" space={1}>
                  <Field>
                    <Stack>
                      <Label>Start Date</Label>
                      <FormikField name="reportPeriodStart">
                        {({ field, form: { setFieldValue } }: FieldProps) => (
                          <DateSelect {...field} onChange={(date) => setFieldValue('reportPeriodStart', date)} />
                        )}
                      </FormikField>
                    </Stack>
                  </Field>

                  <Field>
                    <Stack>
                      <Label>End Date</Label>
                      <FormikField name="reportPeriodEnd">
                        {({ field, form: { setFieldValue } }: FieldProps) => (
                          <DateSelect {...field} onChange={(date) => setFieldValue('reportPeriodEnd', date)} />
                        )}
                      </FormikField>
                    </Stack>
                  </Field>
                </Row>
              </Field>
            </Stack>

            <Stack space={1}>
              <InputGroup label={<FormikField as={Checkbox} label="Display “Prepared For”" name="displayPerson" />}>
                <Row justify="stretch" space={1}>
                  <Field>
                    <Stack>
                      <Label>Person</Label>
                      <FormikField as={TextInput} name="personName" placeholder={t('personName')} />
                    </Stack>
                  </Field>

                  <Field>
                    <Stack>
                      <Label>Title</Label>
                      <FormikField as={TextInput} name="personTitle" placeholder={t('personTitle')} />
                    </Stack>
                  </Field>
                </Row>
              </InputGroup>
            </Stack>

            <Stack space={1}>
              <InputGroup label={<FormikField as={Checkbox} label="Display “Prepared By”" name="customizedPartner" />}>
                <Row justify="stretch" space={0}>
                  <Field>
                    <Stack>
                      <Label>Partner</Label>
                      <FormikField as={TextInput} name="partnerName" placeholder={t('partnerName')} />
                    </Stack>
                  </Field>
                </Row>
              </InputGroup>
            </Stack>

            <Field>
              <Stack>
                <Label>Partner Logo</Label>
                <Box paddingTop={1}>
                  <FormikField name="partnerLogo">
                    {({ form: { setFieldValue } }: FieldProps) => {
                      return (
                        <input
                          accept="image/jpeg,image/png,image/svg+xml"
                          onChange={async ({ target: { files } }) => {
                            if (!files) return;

                            const blob = await validateAndParse(files[0]);
                            if (!blob) return;

                            setFieldValue('customizedPartner', true);
                            setFieldValue('partnerLogo', blob);
                          }}
                          placeholder={t('company')}
                          type="file"
                        />
                      );
                    }}
                  </FormikField>
                </Box>
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>Document Title</Label>
                <FormikField as={TextInput} name="documentTitle" placeholder={t('documentTitle')} />
              </Stack>

              <Stack>
                <Label>Document Date</Label>
                <FormikField name="documentDate">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <DateSelect
                      {...field}
                      onChange={(date) => setFieldValue('documentDate', date)}
                      placeholder={format(new Date(), 'MMMM do yyyy')}
                    />
                  )}
                </FormikField>
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>Overview</Label>
                <FormikField as={TextInput} multiline name="overview" placeholder={t('overview')} />
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>BEC Target Recipients</Label>
                <FormikField as={TextInput} multiline name="becDescription" placeholder={t('becDescription')} />
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>Target Summary</Label>
                <FormikField as={TextInput} multiline name="targetSummary" placeholder={t('targetSummary')} />
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>Threat Origins</Label>
                <FormikField
                  as={TextInput}
                  multiline
                  name="countryOfOriginSummary"
                  placeholder={t('countryOfOriginSummary')}
                />
              </Stack>
            </Field>

            <Field>
              <Stack>
                <Label>Detection Examples</Label>
                <FormikField as={TextInput} multiline name="detectionExamples" placeholder={t('detectionExamples')} />
              </Stack>
            </Field>
          </Stack>
          <ReloadButton />
        </FormikForm>
      </Formik>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function ReloadButton() {
  const { dirty } = useFormikContext();

  return (
    <Position at="middle" zIndex={999}>
      <CircleButton behavior="submit" disabled={!dirty} symbol="pageNext" />
    </Position>
  );
}

//
// Private Functions
// -------------------------------------------------------------------------------------------------

type Source = string | { height?: number; uri?: string; width?: number };

function validateAndParse(file: File): Promise<null | Source> {
  return new Promise((resolve, reject) => {
    try {
      const url = URL.createObjectURL(file);

      const image = new Image();
      image.onerror = () => reject();
      image.onload = function () {
        const { naturalHeight, naturalWidth } = this as HTMLImageElement;

        if (file.type === 'image/svg+xml') {
          const canvas = document.createElement('canvas');
          canvas.height = naturalHeight * 8;
          canvas.width = naturalWidth * 8;
          canvas.style.height = `${naturalHeight * 4}px`;
          canvas.style.width = `${naturalWidth * 4}px`;

          const context = canvas.getContext('2d');
          if (context) {
            context.drawImage(image, 0, 0, naturalWidth * 8, naturalHeight * 8);
            const png = canvas.toDataURL('image/png');
            resolve({ height: naturalHeight * 4, uri: png, width: naturalWidth * 4 });
          } else {
            resolve(null);
          }
        }

        if (naturalWidth < 250) resolve(null);
        resolve({ height: naturalHeight, uri: url, width: naturalWidth });
      };
      image.src = url;
    } catch (error) {
      reject(error);
    }
  });
}
