"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _textUtils = require("./textUtils");
//
// Internal Typescript types
// -------------------------------------------------------------------------------------------------

// Utility type that attachs a prepends a number to the array type
// Utility type that builds an array type to walk down the tree and collect numbers
/**
 * Enumerate can be used to create a type for an index range of an array. Simply pass in the length
 * as `N` to get an _union_ type of numbers, starting at zero.
 *
 *     // Manually created index union type
 *     type Index = 0 | 1 | 2 | 3
 *     // Equivalent index union type with Enumerate
 *     type Index = Enumerate<4>
 *
 */
//
// Colors
// -------------------------------------------------------------------------------------------------
/**
 * Marshall's named color palette
 */
const colors = {
  antiFlashWhite: '#f2f3f5',
  ashGrey: '#b0bec5',
  blueDeFrance: '#2e95ed',
  deepSkyBlue: '#03bffe',
  dimGrey: '#656a77',
  gainsboro: '#d8e0ea',
  ghostWhite: '#f7fbff',
  independence: '#4d5673',
  mellowYellow: '#ffdc82',
  parisGreen: '#4ec987',
  pastelRed: '#ff6969',
  raisinBlack: '#242425',
  scooter: '#27c5da',
  toryBlue: '#204dae',
  turquoise: '#44eabf',
  white: '#fff',
  cfBlue: '#0040d6',
  cfBlueAlt: '#003682',
  cfBlack: '#000000',
  cfGreen: '#2eb35e',
  cfGrey: '#4f4f4f',
  cfLightBlue: '#6ecce5',
  cfLightGrey: '#f9fbfb',
  cfMediumGrey: '#b9b9b9',
  cfOrange: '#f6811e',
  cfRed: '#ff6632'
};

/**
 * All of the colors avaiable within the theme.
 */

/**
 * All of the names of the colors that are availale within the theme.
 */

//
// Fonts
// -------------------------------------------------------------------------------------------------
/**
 * Marshall's font stacks
 */
const fonts = {
  din: '"D-Din", sans-serif',
  inter: '"Inter", sans-serif',
  lato: '"Lato", sans-serif',
  robotoMono: '"Roboto Mono", monospace'
};

/**
 * All of the fonts avaiable within the theme.
 */

/**
 * All of the names of the fonts that are availale within the theme.
 */

/**
 * Standard font sizes to be used in Marshall's UI.
 */
const fontSizes = [10, 12, 14, 16, 24];

/**
 * An index in the font-size dictionary.
 */

//
// Radii
// -------------------------------------------------------------------------------------------------
/**
 * Standard corner radii for components to use.
 */
const radii = [2, 4, 8];

/**
 * An index in the radii dictionary.
 */

//
// Spacing
// -------------------------------------------------------------------------------------------------
/**
 * Standard definitions for spacing UI components. These are calculated off of the theme's over-all
 * overall vertical rhythm.
 */
const spacing = [(0, _textUtils.rhythmUnits)(0.25), (0, _textUtils.rhythmUnits)(0.5), (0, _textUtils.rhythmUnits)(1), (0, _textUtils.rhythmUnits)(2), (0, _textUtils.rhythmUnits)(4)];

/**
 * An index in the spacing unit dictionary.
 */

//
// Semantic UI dictionary
// -------------------------------------------------------------------------------------------------
/**
 * Semantically-named theme settings for a standard way of referencing global design tokens within
 * the theme
 */
/**
 * The names of all of the UI design tokens available
 */
/**
 * Marshall's Semantically-named UI settings
 */
const ui = {
  accentColor: p => p.theme.colors.deepSkyBlue,
  backgroundColor: p => p.theme.colors.antiFlashWhite,
  baseFont: p => p.theme.fonts.robotoMono,
  mutedColor: p => p.theme.colors.antiFlashWhite,
  primaryColor: p => p.theme.colors.deepSkyBlue,
  secondaryColor: p => p.theme.colors.independence,
  textColor: p => p.theme.colors.independence
};

//
// Theme definition
// -------------------------------------------------------------------------------------------------

/**
 * Marshall's shared theme settings
 */
/**
 * Marshall's main theme object. Generally, its for use with styled-components'
 * `<ThemeProvider>` component.
 */
const theme = {
  colors,
  fonts,
  fontSizes,
  radii,
  spacing,
  ui
};
var _default = theme;
exports.default = _default;