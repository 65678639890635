"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hash = hash;
var _hashSum = _interopRequireDefault(require("hash-sum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Returns a tiny, probably-unique string for the value passed in. _**IMPORTANT:** This does not return a
 * cryptographically secure hash._
 */
function hash(size) {
  return (0, _hashSum.default)(size);
}