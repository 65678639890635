import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as resources from './i18n';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    defaultNS: 'common',
    fallbackLng: 'en',
    ns: 'common',
    react: { wait: true },
    resources,
  });

export default i18next;
