import { downloadBlob } from '@a1s/lib';
import { renderBlob, PRAReportProps } from '@a1s/pdf';
import { Box, CircleButton, Position, Row, UIProvider } from '@a1s/ui-deprecated';
import { gql, useQuery, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { format, subDays, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18n from './config/i18next';

import RenderingStatus from './components/RenderingStatus';
import Form from './screens/Form';

import { APIData, EmbeddedImage, FormData } from './data';
import link from './rest-link';

//
// Apollo set up
// -------------------------------------------------------------------------------------------------

const client = new ApolloClient({ cache: new InMemoryCache({}), link, uri: '/graphql' });

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * The root component and entrypoint for the entire Douglas app. Responsible for setting up all of
 * the context providers.
 */
export default function App(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <UIProvider>
          <Main />
        </UIProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface DocumentProps {
  apiData?: APIData;
  formData: FormData;
  onRender?: PRAReportProps['onRender'];
}

const POV_QUERY = gql`
  query POVQuery($input: POVQueryInput) {
    report(input: $input) @rest(endpoint: "reports", method: "POST", path: "/", type: "Report") {
      becTargetedRecipientsData @type(name: "BECTargetedRecipientData") {
        becTargets
        value
      }

      countryOfOriginSummaryData @type(name: "CountryOfOrginData") {
        count
        lat
        lng
      }

      detectionsData @type(name: "Detection") {
        description
        preview
        title
      }

      domainsConfigured @type(name: "Domain") {
        id
        domain
        dmarcPolicy
        dmarcStatus
        spfStatus
      }

      emailTotals: emailTotalsData @type(name: "EmailTotal") {
        detectionStats
        totalEmailsProcessed
      }

      inboxesCount: totalUniqueInboxesCount @type(name: "UniqueInboxesCount") {
        count
      }

      maliciousBecEnabled

      maliciousThreatTypesData @type(name: "MaliciousThreatType") {
        threatType
        value
      }

      threatTypeDescriptions @type(name: "ThreatTypeDescription") {
        description
        name
      }

      targetedRecipientsData @type(name: "TargetedRecipientData") {
        address
        count
      }
    }
  }
`;

type EmbedPDFProps = Omit<DocumentProps, 'onrender'>;

function EmbedPDF(props: EmbedPDFProps) {
  const { documentURL, loading } = usePDF(props);

  useEffect(() => {
    // We use CSS to hide/reveal the `<RenderingStatus/>` component so we don't trigger a
    // re-render with React, which would cause the renderer to cause hang the browser.
    document.body.dataset.renderingPdf = loading.toString();
  }, [loading]);

  if (loading || !documentURL) return <></>;

  return <iframe height="100%" src={documentURL} title="PDF" width="100%" />;
}

/**
 * The `<Main>` component is responsible for data flow within the app. It takes the data that is submitted from the
 * form, sends it to the API, and then passes that data down to the `<Documment>` component so it can render the PDF.
 */
function Main() {
  const { t } = useTranslation('defaults');

  const [formData, setFormData] = useState<FormData>(() => ({
    becDescription: t('becDescription'),
    customizedPartner: false,
    countryOfOriginSummary: t('countryOfOriginSummary'),
    detectionExamples: t('detectionExamples'),
    displayPerson: true,
    documentTitle: t('documentTitle'),
    overview: t('overview'),
    reportPeriodEnd: subDays(new Date(), 1),
    reportPeriodStart: subMonths(new Date(), 1),
    targetSummary: t('targetSummary'),
  }));

  const { companyId: customerId, reportPeriodStart, reportPeriodEnd } = formData;
  const input = {
    customerId,
    fromDate: format(reportPeriodStart || new Date(), 'yyyy-MM-dd'),
    toDate: format(reportPeriodEnd || new Date(), 'yyyy-MM-dd'),
  };
  const { data: rawAPIData } = useQuery<APIData>(POV_QUERY, {
    skip: !customerId || !reportPeriodStart || !reportPeriodEnd,
    variables: { input },
  });

  const [apiData, setAPIData] = useState<APIData | undefined>();
  useEffect(() => {
    async function run() {
      const transformed = await transform(rawAPIData);
      setAPIData(transformed);
    }
    run();
  }, [rawAPIData]);

  async function handlePress() {
    const blob = await renderBlob({ apiData, formData });
    downloadBlob(blob, 'Phishing_Risk_Assessment.pdf');
  }

  function handleSubmit(values: FormData) {
    // Reveal the `<RenderingStatus/>` early, to make sure it is shown before the rendering hangs the brwoser.
    document.body.dataset.renderingPdf = 'true';

    // Give React time to update the UI before it hangs while while the PDF renders.
    setTimeout(() => setFormData(values), 150);
  }

  return (
    <>
      <Row absoluteFill align="stretch" as="main" equal justify="stretch">
        <RenderingStatus />

        <Box backgroundColor="white">
          <Form data={formData} onSubmit={handleSubmit} />
          <Position at="bottomMiddle" zIndex={999}>
            <CircleButton onPress={handlePress} symbol="fileDownload" />
          </Position>
        </Box>

        <Box backgroundColor="antiFlashWhite">
          <EmbedPDF apiData={apiData} formData={formData} />
        </Box>
      </Row>
    </>
  );
}

//
// Private hook
// -------------------------------------------------------------------------------------------------

interface ReturnPDF {
  documentURL?: string;
  loading: boolean;
}

function usePDF(props: DocumentProps): ReturnPDF {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [loading, setLoading] = useState(true);
  const [documentURL, setDocumentURL] = useState<string | undefined>();

  useEffect(() => {
    async function generateBlob() {
      setLoading(true);

      const blob = await renderBlob(props);
      if (blob) setDocumentURL(window.URL.createObjectURL(blob));

      setLoading(false);
    }

    if (firstLoad || props.apiData) {
      generateBlob();
    } else {
      setLoading(false);
      setDocumentURL(undefined);
    }

    setFirstLoad(false);
    // eslint-disable-next-line
  }, [props]);

  return { documentURL, loading };
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

/**
 * Takes an image source and turns it into an `EmbeddedImage` by loading the image and figuring out
 * what the height and width of the image are.
 */
function embeddedImage(src: string): Promise<EmbeddedImage | undefined> {
  return new Promise((resolve) => {
    if (!src) resolve(undefined);

    const image = new Image();
    image.onerror = () => resolve(undefined);
    image.onload = () => {
      // eslint-disable-next-line
      console.log({ data: src, height: image.naturalHeight, width: image.naturalWidth });
      resolve({ data: src, height: image.naturalHeight, width: image.naturalWidth });
    };
    image.src = src;
  });
}

async function transform(apiData?: APIData): Promise<APIData | undefined> {
  if (!apiData) return undefined;

  return {
    report: {
      ...apiData.report,
      detectionsData: await Promise.all(
        apiData.report.detectionsData.map(async ({ description, preview, title }) => {
          if (!preview || preview?.length > 96000) return { description, preview, title };

          try {
            return { description, image: await embeddedImage(preview), preview, title };
          } catch (_) {
            return { description, preview, title };
          }
        })
      ),
    },
  };
}
