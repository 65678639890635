import React from 'react';
import styled from 'styled-components';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Base = styled.div`
  background-color: ${(p) => p.theme.colors.raisinBlack};
  border-radius: 8px;
  box-shadow: #000000ff 0 0 600px;
  color: ${(p) => p.theme.colors.ghostWhite};
  display: none;
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  left: 75%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: ${(p) => p.theme.spacing[1]}px;
  z-index: 1000;

  body[data-rendering-pdf='true'] & {
    display: block;
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

/**
 * `RenderingStatus` work differently than most React component. In order to avoid trigger a rerender
 * and causing the the PDF rendering to hang the browser, it relies on CSS and `data-` attributes to
 * show and high the component.
 */
export default function RenderingStatus(): JSX.Element | null {
  return <Base>Creating your PDF...</Base>;
}
