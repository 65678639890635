"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SYMBOL_SIZES = exports.SVG = exports.Path = void 0;
var _polished = require("polished");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//
// Internal Typescript types
// -------------------------------------------------------------------------------------------------

// Utility type that attachs a prepends a number to the array type

// Utility type that builds an array type to walk down the tree and collect numbers

/**
 * Enumerate can be used to create a type for an index range of an array. Simply pass in the length
 * as `N` to get an _union_ type of numbers, starting at zero.
 *
 *     // Manually created index union type
 *     type Index = 0 | 1 | 2 | 3
 *     // Equivalent index union type with Enumerate
 *     type Index = Enumerate<4>
 *
 */

//
// Size setting
// -------------------------------------------------------------------------------------------------

/**
 * The variate sizes the symbols will render as
 */
const SYMBOL_SIZES = [12, 16, 24, 48, 72];

/**
 * An index in the sizes list.
 */

//
//
// -------------------------------------------------------------------------------------------------
exports.SYMBOL_SIZES = SYMBOL_SIZES;
//
// Shared styled-components for the symbol components to use
// -------------------------------------------------------------------------------------------------

const Path = _styledComponents.default.path`
  fill: currentColor;

  ${({
  fillColor,
  theme
}) => typeof fillColor !== 'undefined' && (0, _styledComponents.css)`
      fill: ${theme.colors[fillColor]};
    `};
`;
exports.Path = Path;
const SVG = _styledComponents.default.svg`
  height: ${p => (0, _polished.rem)(SYMBOL_SIZES[p.heightAndWidth])};
  width: ${p => (0, _polished.rem)(SYMBOL_SIZES[p.heightAndWidth])};
`;
exports.SVG = SVG;