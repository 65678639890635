"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  adjustFontSizeTo: true
};
exports.adjustFontSizeTo = adjustFontSizeTo;
var _polished = require("polished");
var _styledComponents = require("styled-components");
var _textUtils = require("./textUtils");
Object.keys(_textUtils).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _textUtils[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _textUtils[key];
    }
  });
});
function adjustFontSizeTo(size) {
  const {
    fontSize,
    lineHeight
  } = _textUtils.rhythm.adjustFontSizeTo((0, _polished.rem)(size));
  return (0, _styledComponents.css)`
    font-size: ${fontSize};
    line-height: ${lineHeight};
  `;
}